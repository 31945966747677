import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import DrillContainer from '@/views/components/DrillContainer.vue'
import CommonContainer from '@/views/components/CommonContainer.vue'
import MajorList from '@/views/MajorList.vue'
import CourseList from '@/views/CourseList.vue'
import Mine from '@/views/Mine.vue'
import FragmentView from '@/views/FragmentView.vue'
import FragmentDetail from '@/views/FragmentDetail.vue'
import FragmentSearch from '@/views/FragmentSearch.vue'
import KnowledgeMap from '@/views/KnowledgeMap.vue'
import CourseSearch from '@/views/CourseSearch.vue'
import CourseHistory from '@/views/CourseHistory.vue'
import Register from '@/views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: CommonContainer,
    children: [{
      path: '/',
      name: 'home',
      meta: {
        title: '云碎片'
      },
      component: Home
    },{
      path: '/major',
      name: 'major',
      meta: {
        title: '全部专业'
      },
      component: MajorList
    },{
      path: '/courseList/:majorId',
      name: 'courseList',
      component: CourseList
    },{
      path: '/courseSearch',
      name: 'courseSearch',
      meta: {
        title: '搜索课程'
      },
      component: CourseSearch
    },{
      path: '/courseHistory',
      name: 'courseHistory',
      meta: {
        title: '最近学习'
      },
      component: CourseHistory
    },{
      path: '/fragmentSearch/:majorId',
      name: 'fragmentSearch',
      component: FragmentSearch
    },{
      path: '/mine',
      name: 'mine',
      component: Mine
    }
    ,{
      path: '/fragment/:knowledgeId/:courseStandardId/:knowledgeName/:index/:subIndex/:fid',
      name: 'fragmentView',
      component: FragmentView
    },{
      path: '/fragmentDetail',
      name: 'fragmentDetail',
      component: FragmentDetail
    },{
      path: '/knowledgeMap',
      name: 'knowledgeMap',
      component: KnowledgeMap
    },{
      path: '/register',
      name: 'register',
      component: Register
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
