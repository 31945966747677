/**
 * 用户接口
 * 用户接口API
 *
 * OpenAPI spec version: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import ApiClient from '../../ApiClient';
import AccountMaskPhoneNumVo from '../../token/model/AccountMaskPhoneNumVo';
import ChaoxingCodeForm from '../../token/model/ChaoxingCodeForm';
import ChaoxingJSBridgeSignalVo from '../../token/model/ChaoxingJSBridgeSignalVo';
import CheckOldPhoneNumForm from '../../token/model/CheckOldPhoneNumForm';
import CheckPhoneNumForm from '../../token/model/CheckPhoneNumForm';
import CompleteInfoForm from '../../token/model/CompleteInfoForm';
import CreatedVO from '../../token/model/CreatedVO';
import DingCodeForm from '../../token/model/DingCodeForm';
import ExisVo from '../../token/model/ExisVo';
import LoginTokenVo from '../../token/model/LoginTokenVo';
import NameIdNumberForm from '../../token/model/NameIdNumberForm';
import PwdLoginForm from '../../token/model/PwdLoginForm';
import RegisterAccountForm from '../../token/model/RegisterAccountForm';
import ResetNameIdNumberPwdForm from '../../token/model/ResetNameIdNumberPwdForm';
import ResetPhoneNumPwdForm from '../../token/model/ResetPhoneNumPwdForm';
import SendMsgFrom from '../../token/model/SendMsgFrom';
import SmsSignupForm from '../../token/model/SmsSignupForm';
import TokenRefreshForm from '../../token/model/TokenRefreshForm';
import UpdatedVo from '../../token/model/UpdatedVo';
  /**
   * Token service.
   * @module token/api/TokenApi
   * @version 0.2.0
   */

  /**
   * Constructs a new TokenApi. 
   * @alias module:token/api/TokenApi
   * @class
   * @param {module:ApiClient} apiClient Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  var exports = function(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;



    /**
     * 检查学生输入信息与教务系统是否一致
     * @param {module:token/model/NameIdNumberForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/ExisVo}
     */
this.checkJwStudentExist = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling checkJwStudentExist";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = ExisVo;
      return this.apiClient.callApi(
        '/api/center/token/check_jw_student_exsit', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 检查唯一身份证号账号绑定的完整手机号是否输入正确
     * @param {module:token/model/CheckOldPhoneNumForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/ExisVo}
     */
this.checkOldPhoneNum = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling checkOldPhoneNum";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = ExisVo;
      return this.apiClient.callApi(
        '/api/center/token/check_old_phone', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 判断⼿机号是否存在(account表
     * @param {module:token/model/CheckPhoneNumForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/ExisVo}
     */
this.checkPhoneNumExist = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling checkPhoneNumExist";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = ExisVo;
      return this.apiClient.callApi(
        '/api/center/token/check_phone_num_exist', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 钉钉信息不完善时，手动补全信息
     * @param {module:token/model/CompleteInfoForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.completeInfo = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling completeInfo";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/complete_info', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取超星JSBridge的At参数,
     * @param {Object} opts Optional parameters
     * @param {String} opts.discriminator 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/ChaoxingJSBridgeSignalVo}
     */
this.getChaoxingJSBridgeSignal = function(opts) {
      opts = opts || {};
      var postBody = null;


      var pathParams = {
      };
      var queryParams = {
        'discriminator': opts['discriminator']
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = ChaoxingJSBridgeSignalVo;
      return this.apiClient.callApi(
        '/api/center/token/chaoxing/jsbridge_signal', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 获取账号信息
     * @param {module:token/model/NameIdNumberForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/AccountMaskPhoneNumVo}
     */
this.getIdNumerAccount = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getIdNumerAccount";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = AccountMaskPhoneNumVo;
      return this.apiClient.callApi(
        '/api/center/token/get_id_num_account', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 超星登录
     * @param {module:token/model/ChaoxingCodeForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByChaoxingCode = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByChaoxingCode";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/chaoxing/login_by_code', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 钉钉H5换取token
     * @param {module:token/model/DingCodeForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByH5DingCode = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByH5DingCode";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/h5', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 钉钉移动客户端登录换取token
     * @param {module:token/model/DingCodeForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByNativeDingCode = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByNativeDingCode";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/ding/native', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 手机号密码换取token
     * @param {module:token/model/PwdLoginForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.getTokenByPwd = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling getTokenByPwd";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/pwd', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 刷新token
     * @param {module:token/model/TokenRefreshForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/LoginTokenVo}
     */
this.refreshToken = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling refreshToken";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = LoginTokenVo;
      return this.apiClient.callApi(
        '/api/center/token/refresh_token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 新建账号
     * @param {module:token/model/RegisterAccountForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/CreatedVO}
     */
this.registerAccount = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling registerAccount";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CreatedVO;
      return this.apiClient.callApi(
        '/api/center/token/register_account', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 重设账号绑定的姓名、身份证号和密码
     * @param {module:token/model/ResetNameIdNumberPwdForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/UpdatedVo}
     */
this.resetNameIdNumberPwd = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling resetNameIdNumberPwd";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = UpdatedVo;
      return this.apiClient.callApi(
        '/api/center/token/reset_name_id_number_pwd', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 重设账号绑定的手机号和密码
     * @param {module:token/model/ResetPhoneNumPwdForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/UpdatedVo}
     */
this.resetPhoneNumPwd = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling resetPhoneNumPwd";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = UpdatedVo;
      return this.apiClient.callApi(
        '/api/center/token/reset_phone_num_pwd', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 发送手机验证码
     * @param {module:token/model/SendMsgFrom} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/CreatedVO}
     */
this.sendPhoneMsg = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling sendPhoneMsg";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;
      isPublic = true;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CreatedVO;
      return this.apiClient.callApi(
        '/api/center/token/send_msg', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }


    /**
     * 手机号验证码注册
     * @param {module:token/model/SmsSignupForm} body 请求参数body
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:token/model/CreatedVO}
     */
this.signupBySms = function(body) {
      var postBody = body;

      // verify the required parameter 'body' is set
      if (body == undefined || body == null) {
        throw "Missing the required parameter 'body' when calling signupBySms";
      }


      var pathParams = {
      };
      var queryParams = {
      };
      var headerParams = {
      };
      var formParams = {
      };

      var isPublic = false;
      isPublic = true;

      var authNames = [];
      var contentTypes = [];
      var accepts = [];
      var returnType = CreatedVO;
      return this.apiClient.callApi(
        '/api/center/token/signup_by_sms', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType,isPublic
      );
    }
  };

  export default exports;
